import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Header from '../../pages/partials/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    background: theme.palette.background.default,
  },
  content: {
    height: '100%',
    paddingTop: 70,
  },
}));

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Layout;
