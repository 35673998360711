import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { IconType } from 'react-icons';

import { useStyles } from './styles';

interface MenuItemProps {
  title: string;
  destination?: string;
  icon?: IconType | React.FunctionComponent;
  onClick: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ icon: Icon, title, onClick }) => {
  const classes = useStyles();

  return (
    <ListItem button onClick={onClick} className={classes.listItem}>
      {Icon && (
        <ListItemIcon className="list-icon">
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText className="list-text" primary={title} />
    </ListItem>
  );
};

export default MenuItem;
