import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';
import LeftMenu from './LeftMenu';
import Actions from './Actions';
import Table from './Table';
import { FilterName, initialFilters } from './props';
import api from '../../services/api';
import { ListDTO } from '../../dtos/ResultsDTO';
import { WithdrawDTO } from '../../dtos/WithdrawDTO';
import { Skeleton } from '@material-ui/lab';

interface GetWithdrawsOptions {
  page: number;
}

const Financial: React.FC = () => {
  const [withdraws, setWithdraws] = useState<WithdrawDTO[]>([]);
  const [filters, setFilters] = useState(initialFilters);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const getWithdraws = useCallback(
    (options?: GetWithdrawsOptions) => {
      setLoading(true);
      const params = new URLSearchParams();
      let page: number = currentPage + 1;
      let limit: number | undefined = rowsPerPage;
      if (options && Object.keys(options).length) {
        page = options.page;
        limit = undefined;
      }
      params.set('page', page.toString());
      if (limit) {
        params.set('limit', rowsPerPage.toString());
      }

      Object.entries(filters).forEach(([key, value]) => {
        if (value !== null && value !== '') params.set(key, value);
      });

      api
        .get<ListDTO<WithdrawDTO>>('/admin/withdraw-payments', { params })
        .then((result) => {
          setWithdraws(result.data.data);
          setTotal(result.data.total);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [currentPage, rowsPerPage, filters]
  );

  const handleFilterChange = useCallback((name: FilterName, value: unknown) => {
    setFilters((oldFilters) => ({ ...oldFilters, [name]: value }));
  }, []);

  useEffect(() => {
    getWithdraws();
  }, [currentPage, filters, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowsPerPageChange = useCallback((pages: number) => {
    setRowsPerPage(pages);
    setCurrentPage(0);
  }, []);

  return (
    <div className={classes.root}>
      <LeftMenu onFilterChange={handleFilterChange} filters={filters} />
      <div className={classes.mainArea}>
        <div className={classes.headerContainer}>
          <Typography component="div" className={classes.title}>
            Bem-vindo(a), Financeiro
          </Typography>
          <Actions
            search={filters.search}
            onSubmit={handleFilterChange}
            filters={filters}
          />
        </div>
        {withdraws.length > 0 ? (
          <Table
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            withdraws={withdraws}
            total={total}
            loading={loading}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={handleRowsPerPageChange}
            onUpdate={getWithdraws}
          />
        ) : loading ? (
          <Skeleton variant="rect" width="100%" height="500px" />
        ) : null}
      </div>
    </div>
  );
};

export default Financial;
