import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: '#0054F0',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 55,
    margin: 0,
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  menu: {
    height: '100%',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `5px solid transparent`,
    padding: '0 50px',
    cursor: 'pointer',
    '&.active': {
      borderBottomColor: theme.palette.background.paper,
    },
  },
  userSection: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.paper,
    '& span': {
      marginRight: 10,
      textTransform: 'none',
      userSelect: 'none',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  list: {},
  listItem: {
    '& .list-icon': {},
    '& .list-text': {},
  },
}));
