export const fullName = (firstname: string, lastname: string): string => {
  return `${firstname} ${lastname}`;
};

export const getDateBr = (value: string | null): string => {
  if (!value) return '';
  const date = new Date(value);
  return date.toLocaleDateString('pt-BR');
};

export const getShortDateBr = (value: string | null): string => {
  if (!value) return '';
  return value.split('-').reverse().join('/');
};

export const getDateIso = (value: string | null): string => {
  if (!value) return '';
  return value.split('/').reverse().join('-');
};

export const phoneFormat = (input: string): string => {
  const phone = input.replace(/\D/g, '');
  const isEven = phone.length % 2 === 0;
  const ddd = isEven ? phone.substr(-10, 2) : phone.substr(-11, 2);
  const firstNumber = isEven ? phone.substr(-8, 4) : phone.substr(-9, 5);
  const secondNumber = phone.substr(-4);
  return `(${ddd}) ${firstNumber}-${secondNumber}`;
};

export const getCurrencyBr = (value: string | number): string => {
  const convertedValue = typeof value === 'string' ? parseFloat(value) : value;
  const currency = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return currency.format(convertedValue);
};
