interface CrmFilters {
  new_indiker: boolean;
  no_contact: boolean;
  quality: 0 | 1 | 2 | 3 | 4 | 5;
}

const initialCrmFilters: CrmFilters = {
  new_indiker: false,
  no_contact: false,
  quality: 0,
};

interface ApiFilters {
  noIndicationIn: 15 | 30 | 60 | null;
  oneIndication: boolean;
  indikerMaster: boolean;
  indikerChild: boolean;
  inactive: boolean;
  search: string;
  date: string;
}

const initialApiFilters: ApiFilters = {
  noIndicationIn: null,
  oneIndication: false,
  indikerMaster: false,
  indikerChild: false,
  inactive: false,
  search: '',
  date: '',
};

export type Filters = CrmFilters & ApiFilters;

export type FilterName = keyof Filters;

export const initialFilters: Filters = {
  ...initialApiFilters,
  ...initialCrmFilters,
};

export interface FilterProps {
  name: FilterName;
  value: unknown;
}

export const crmFiltersList = Object.keys(initialCrmFilters);

export const apiFiltersList = Object.keys(initialApiFilters);

export type ActiveFiltersGroup = 'api' | 'crm' | null;
