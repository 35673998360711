import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import App from './App';
import fakeServer from './mock/server';

if (
  process.env.REACT_APP_MOCK_API === 'true' &&
  typeof fakeServer === 'function'
) {
  fakeServer();
}

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
