import { createTheme } from '@material-ui/core/styles';

import { palette } from './palette';

export const theme = createTheme({
  palette,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          margin: 0,
          padding: 0,
        },
        body: {
          margin: 0,
          padding: 0,
          height: '100vh',
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#EBEDEF',
      },
    },
    MuiInputAdornment: {
      root: {
        color: '#828282DE',
        cursor: 'pointer',
      },
    },
    MuiButton: {
      contained: {
        borderRadius: 5,
        padding: 16,
        fontSize: '16px',
        backgroundColor: '#EFEFEF',
        color: '#000000DE',
        boxShadow: '0px 3px 3px #00000024',
      },
      text: {
        padding: 0,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 3px 3px #00000024',
      },
      rounded: {
        borderRadius: 5,
      },
    },
    MuiChip: {
      root: {
        height: 25,
        fontSize: '12px',
        fontWeight: 500,
        textTransform: 'uppercase',
      },
      label: {
        paddingLeft: 14,
        paddingRight: 14,
      },
    },
    MuiTableCell: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
  },
});
