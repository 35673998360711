import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    fontSize: '18px',
    fontWeight: 900,
    textAlign: 'center',
    userSelect: 'none',
    '&.primary': {
      color: theme.palette.primary.main,
    },
    '&.contrast': {
      color: theme.palette.background.paper,
    },
    '& span': {
      fontSize: '14px',
      fontWeight: 400,
      marginLeft: 5,
    },
  },
}));

interface LogoProps {
  color: 'primary' | 'contrast';
}

const Logo: React.FC<LogoProps> = ({ color }) => {
  const classes = useStyles();

  return (
    <Typography component="div" className={`${classes.logo} ${color}`}>
      IndikeGanhe
      <span>CRM</span>
    </Typography>
  );
};

export default Logo;
