import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface MenuItemProps {
  title: string;
  route: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ title, route }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();
  const classList = [classes.menu];
  if (pathname === route) classList.push('active');

  return (
    <Typography
      component="div"
      className={classList.join(' ')}
      onClick={() => history.push(route)}
    >
      {title}
    </Typography>
  );
};

const Menu: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.menuContainer}>
      <MenuItem title="Inicio" route="/" />
      <MenuItem title="Financeiro" route="/financeiro" />
    </div>
  );
};

export default Menu;
