export interface Filters {
  search: string;
  status: number | null;
}

export type FilterName = keyof Filters;

export const initialFilters: Filters = {
  search: '',
  status: null,
};
