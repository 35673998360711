import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const palette: PaletteOptions = {
  primary: {
    main: '#A7A7A7',
  },
  background: {
    default: '#FAFAFA',
    paper: '#FFFFFF',
  },
  text: {
    primary: '#000000',
  },
};
