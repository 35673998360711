import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './styles';
import LeftMenu from './LeftMenu';
import {
  ActiveFiltersGroup,
  crmFiltersList,
  FilterName,
  FilterProps,
  initialFilters,
} from './props';
import Actions from './Actions';
import Table from './Table';
import { useAuth } from '../../context/auth';
import { IndikerDTO } from '../../dtos/IndikerDTO';
import { ListDTO } from '../../dtos/ResultsDTO';
import api from '../../services/api';

interface GetIndikerOptions {
  page: number;
}

const Home: React.FC = () => {
  const [indikers, setIndikers] = useState<IndikerDTO[]>([]);
  const [filters, setFilters] = useState(initialFilters);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const classes = useStyles();

  const activeFiltersGroup = useMemo(() => {
    let group: ActiveFiltersGroup = null;

    for (const [key, value] of Object.entries(filters)) {
      if (initialFilters[key as FilterName] !== value) {
        group = crmFiltersList.includes(key) ? 'crm' : 'api';
        break;
      }
    }

    return group;
  }, [filters]);

  const getIndikers = useCallback(
    (options?: GetIndikerOptions) => {
      setLoading(true);
      const params = new URLSearchParams();
      let page: number = currentPage + 1;
      let limit: number | undefined = rowsPerPage;
      if (options && Object.keys(options).length) {
        page = options.page;
        limit = undefined;
      }
      params.set('order', 'DESC');
      params.set('page', page.toString());
      if (limit) {
        params.set('limit', rowsPerPage.toString());
      }

      Object.entries(filters).forEach(([key, value]) => {
        if (value) params.set(key, value);
      });

      api
        .get<ListDTO<IndikerDTO>>('/admin/indikers', { params })
        .then((result) => {
          setIndikers(result.data.data);
          setTotal(result.data.total);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [currentPage, rowsPerPage, filters]
  );

  const handleFilterChange = useCallback((name: FilterName, value: any) => {
    setFilters((oldFilters) => ({ ...oldFilters, [name]: value }));
  }, []);

  const handleFiltersChange = useCallback((appliedFilters: FilterProps[]) => {
    setFilters((oldFilters) => {
      const newFilters = { ...oldFilters };
      appliedFilters.forEach((filter) => {
        Object.assign(newFilters, { [filter.name]: filter.value });
      });
      return newFilters;
    });
  }, []);

  useEffect(() => {
    getIndikers();
  }, [currentPage, filters, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowsPerPageChange = useCallback((pages: number) => {
    setRowsPerPage(pages);
    setCurrentPage(0);
  }, []);

  return (
    <div className={classes.root}>
      <LeftMenu
        onFilterChange={handleFilterChange}
        filters={filters}
        activeFiltersGroup={activeFiltersGroup}
      />
      <div className={classes.mainArea}>
        <div className={classes.headerContainer}>
          <Typography component="div" className={classes.title}>
            Bem-vindo(a), {user.firstname}
          </Typography>
          <Actions
            search={filters.search}
            onSubmit={handleFiltersChange}
            filters={filters}
            activeFiltersGroup={activeFiltersGroup}
          />
        </div>
        {loading ? (
          <Skeleton variant="rect" width="100%" height="500px" />
        ) : (
          <Table
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            indikers={indikers}
            total={total}
            loading={loading}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={handleRowsPerPageChange}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
