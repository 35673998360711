import React, { useCallback } from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FiFilter, FiMinusCircle } from 'react-icons/fi';

import { useStyles } from './styles';
import { FilterName, FiltersProps } from './props';
import { useData } from '../../context/data';

interface LeftMenuProps {
  filters: FiltersProps;
  onFilterChange(name: FilterName, value: unknown): void;
}

const LeftMenu: React.FC<LeftMenuProps> = ({ filters, onFilterChange }) => {
  const { indicationStatuses: statusList } = useData();
  const classes = useStyles();

  const handleClick = useCallback(
    (id: number | null) => {
      onFilterChange('status', id);
    },
    [onFilterChange]
  );

  return (
    <div className={classes.leftMenu}>
      <Typography component="div" className="menu-section">
        <FiFilter />
        Status
        <IconButton
          className="remove-btn"
          title="Remover filtro"
          onClick={() => handleClick(null)}
        >
          <FiMinusCircle />
        </IconButton>
      </Typography>
      {!statusList.length ? (
        <Skeleton variant="rect" width="100%" height="30%">
          &nbsp;
        </Skeleton>
      ) : (
        statusList.map((status) => {
          const classesList = ['menu-item'];
          if (filters.status === status.id) classesList.push('active');
          return (
            <Button
              key={status.id}
              className={classesList.join(' ')}
              onClick={() => handleClick(status.id)}
            >
              {status.name}
            </Button>
          );
        })
      )}
    </div>
  );
};

export default LeftMenu;
