import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      background: '#F2F5F8',
    },
    '& .MuiCard-root': {
      background: '#FFFFFF',
    },
    '& .MuiDialogContent-root': {
      padding: 50,
    },
    '& .MuiDialogActions-root': {
      padding: 20,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: '15px',
  },
  confirmationLabel: {
    fontSize: 14,
    marginBottom: '15px',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
    '& .MuiButton-root': {
      padding: '10px 20px',
      '&.primary': {
        background: '#A7FF75',
      },
    },
    '& .MuiButton-label': {
      fontSize: '12px',
    },
  },
});
