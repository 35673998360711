import React, { useCallback, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { FaCalendar, FaDownload, FaFilter, FaSearch } from 'react-icons/fa';
import fileSaver from 'file-saver';

import { useStyles } from './styles';
import { ActiveFiltersGroup, FilterProps, Filters } from './props';
import api from '../../services/api';
import { getDateIso, getShortDateBr } from '../../utils/format';

interface ActionsProps {
  search: string;
  onSubmit(filters: FilterProps[]): void;
  filters: Filters;
  activeFiltersGroup?: ActiveFiltersGroup;
}

const Actions: React.FC<ActionsProps> = ({
  activeFiltersGroup,
  search,
  onSubmit,
  filters,
}) => {
  const [inputValue, setInputValue] = useState(search);
  const [dateValue, setDateValue] = useState(() => {
    return getShortDateBr(filters.date);
  });
  const classes = useStyles();

  const handleSearch = useCallback(
    (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (inputValue !== search || filters.date !== dateValue) {
        onSubmit([
          { name: 'search', value: inputValue },
          { name: 'date', value: getDateIso(dateValue) },
        ]);
      }
    },
    [dateValue, filters.date, inputValue, onSubmit, search]
  );

  const handleExport = useCallback(() => {
    const params = new URLSearchParams();
    params.set('export', 'true');
    Object.entries(filters).forEach(([key, value]) => {
      if (value) params.set(key, value);
    });

    api
      .get('/admin/indikers', { params, responseType: 'arraybuffer' })
      .then((result) => {
        const contentDisposition: string =
          result.headers['content-disposition'];
        const filename = contentDisposition.split('="')[1].replace('"', '');
        const blob = new Blob([result.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, filename);
      });
  }, [filters]);

  return (
    <div className={classes.actions}>
      <form onSubmit={handleSearch}>
        <TextField
          name="date"
          variant="filled"
          label="Data de entrada"
          value={dateValue}
          disabled={activeFiltersGroup === 'crm'}
          onChange={(ev) => setDateValue(ev.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FaCalendar />
              </InputAdornment>
            ),
          }}
          style={{ marginRight: '10px' }}
        />
        <TextField
          name="search"
          variant="filled"
          label="Nome do indiker"
          value={inputValue}
          disabled={activeFiltersGroup === 'crm'}
          onChange={(ev) => setInputValue(ev.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FaSearch />
              </InputAdornment>
            ),
          }}
        />
        <IconButton type="submit" title="Aplicar filtros">
          <FaFilter />
        </IconButton>
      </form>
      {/* <Button className={classes.exportBtn} onClick={handleExport}>
        <FaDownload />
        Exportar
      </Button> */}
    </div>
  );
};

export default Actions;
