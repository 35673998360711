import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from './styles';
import { useToast } from '../../context/toast';
import api from '../../services/api';
import { emailValidator } from '../../utils/validators';

interface FormData {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .test('Email', 'E-mail inválido', (value) => emailValidator(value)),
});

const ForgotPassword: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const classes = useStyles();

  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        await api.post('/admin/auth/forgot-password', values);
        addToast({
          message: 'E-mail enviado com éxito',
          type: 'success',
        });
        history.push('/redefinir-senha', { email: values.email });
      } catch (error: any) {
        console.error(error);
        const errorMsg = Boolean(error.error)
          ? String(error.error)
          : 'Erro na tentativa de redefinir a senha';
        addToast({
          message: errorMsg,
          type: 'error',
        });
      }
    },
    [addToast, history]
  );

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <Typography component="div" className={classes.title}>
          Esqueci minha senha!
        </Typography>
        <Typography component="div" className={classes.text}>
          Informe seu e-mail para receber o código de recuperação
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="email"
            name="email"
            label="Seu e-mail"
            className={classes.field}
            variant="outlined"
            fullWidth
            error={formik.touched.email && !!formik.errors.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            helperText={(formik.touched.email && formik.errors.email) || null}
          />
          <div className={classes.btnContainer}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => history.push('/login')}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Enviar
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default ForgotPassword;
