import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from './styles';
import { useToast } from '../../context/toast';
import api from '../../services/api';

interface FormData {
  token: string;
  password: string;
  password_confirmation: string;
}

interface HistoryLocationState {
  email: string;
}

const validationSchema = Yup.object().shape({
  token: Yup.string().required('Token obrigatório'),
  password: Yup.string()
    .required('Senha obrigatória')
    .min(8, 'Mínimo de 8 caracteres'),
  password_confirmation: Yup.string()
    .required('A confirmação da senha é requerida')
    .oneOf([Yup.ref('password'), null], 'As senhas não coincidem'),
});

const initialValues: FormData = {
  token: '',
  password: '',
  password_confirmation: '',
};

// Time to enable Resend button
const timerLenght = 60;

const ResetPassword: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(timerLenght);
  const history = useHistory<HistoryLocationState>();
  const { addToast } = useToast();
  const classes = useStyles();
  const { email } = history.location.state;

  useEffect(() => {
    if (timeLeft > 0) {
      setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    }
  }, [timeLeft]);

  const handleResendEmail = useCallback(async () => {
    try {
      await api.post('/admin/auth/forgot-password', { email });
      setTimeLeft(timerLenght);
      addToast({
        message: 'E-mail enviado com sucesso',
        type: 'success',
      });
    } catch (error: any) {
      console.error(error);
      const errorMsg = Boolean(error.error)
        ? String(error.error)
        : 'Erro na tentativa de redefinir a senha';
      addToast({
        message: errorMsg,
        type: 'error',
      });
    }
  }, [addToast, email]);

  const handleSubmit = useCallback(
    async (values: FormData) => {
      Object.assign(values, { email });
      try {
        await api.post('/admin/auth/reset-password', values);
        addToast({
          message: 'Senha redefinida. Agora pode fazer login',
          type: 'success',
        });
        history.push('/login');
      } catch (error: any) {
        console.error(error);
        const errorMsg = Boolean(error.error)
          ? String(error.error)
          : 'Erro na tentativa de redefinir a senha';
        addToast({
          message: errorMsg,
          type: 'error',
        });
      }
    },
    [addToast, email, history]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <Typography component="div" className={classes.title}>
          Verifique seu e-mail
        </Typography>
        <Typography component="div" className={classes.text}>
          Informe seu e-mail para receber o código de recuperação
        </Typography>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <TextField
            id="token"
            name="token"
            label="Código"
            className={classes.tokenField}
            variant="outlined"
            fullWidth
            autoFocus
            value={formik.values.token}
            error={formik.touched.token && !!formik.errors.token}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.token && formik.errors.token}
          />
          <div className={classes.textContainer}>
            <Typography component="span">Não recebeu o código?</Typography>
            <Button
              type="button"
              disabled={timeLeft !== 0}
              className={classes.resendBtn}
              onClick={handleResendEmail}
            >
              Reenviar
            </Button>
            <Typography component="span" className={classes.counter}>
              ({timeLeft}s)
            </Typography>
          </div>
          <TextField
            id="password"
            name="password"
            type="password"
            label="Nova senha"
            variant="outlined"
            fullWidth
            className={classes.field}
            value={formik.values.password}
            error={formik.touched.password && !!formik.errors.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            id="password_confirmation"
            name="password_confirmation"
            type="password"
            label="Confirme sua senha"
            variant="outlined"
            fullWidth
            className={classes.field}
            value={formik.values.password_confirmation}
            error={
              formik.touched.password_confirmation &&
              !!formik.errors.password_confirmation
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={
              formik.touched.password_confirmation &&
              formik.errors.password_confirmation
            }
          />
          <div className={classes.btnContainer}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => history.push('/login')}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Enviar
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default ResetPassword;
