import React from 'react';
import { Avatar as MuiAvatar } from '@material-ui/core';

interface AvatarProps {
  imageUrl: string | null;
  firstname: string;
  lastname: string;
  size?: number;
  fontSize?: string;
}

const Avatar: React.FC<AvatarProps> = ({
  imageUrl,
  firstname,
  lastname,
  size,
  fontSize,
}) => (
  <div style={{ fontSize }}>
    <MuiAvatar
      src={imageUrl || undefined}
      style={{ width: size, height: size, fontSize: '1em', fontWeight: 700 }}
    >
      {[firstname, lastname]
        .map((item) => {
          return item.substr(0, 1);
        })
        .join('')
        .toLocaleUpperCase()}
    </MuiAvatar>
  </div>
);

export default Avatar;
