import { IndicationStatusDTO } from '../dtos/IndicationDTO';
import { TagDTO } from '../dtos/IndikerDTO';
import { WithdrawStatusDTO } from '../dtos/WithdrawDTO';

export interface CrmStatusDTO {
  id: number;
  title: string;
}

export interface ContextData {
  indicationStatuses: IndicationStatusDTO[];
  indikerCrmStatuses: CrmStatusDTO[];
  tagsList: TagDTO[] | null;
  withdrawStatuses: WithdrawStatusDTO[];
  getIndicationStatuses(): Promise<void>;
  getTagsList(): Promise<void>;
}

export const withdrawStatuses: WithdrawStatusDTO[] = [
  { id: 1, name: 'Solicitado', color: '#9B94FF' },
  { id: 2, name: 'Agendado', color: '#FFA264' },
  { id: 3, name: 'Pago', color: '#6ACB53' },
  { id: 0, name: 'Cancelado', color: '#FF5757' },
];

export const indikerCrmStatuses: CrmStatusDTO[] = [
  { id: 1, title: 'Entrar em contato' },
  { id: 2, title: 'Contato feito' },
];
