import React, { createContext, useCallback, useState, useContext } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

interface LoadingContextData {
  startLoading(): void;
  stopLoading(): void;
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData
);

const LoadingProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const startLoading = useCallback(() => {
    setIsOpen(true);
  }, []);

  const stopLoading = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <LoadingContext.Provider value={{ startLoading, stopLoading }}>
      {children}
      <Backdrop open={isOpen}>
        <CircularProgress color="primary" />
      </Backdrop>
    </LoadingContext.Provider>
  );
};

const useLoading = (): LoadingContextData => {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }

  return context;
};

export { LoadingProvider, useLoading };
