import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Routes';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Indiker from '../pages/Indiker';
import Financial from '../pages/Financial';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" component={Home} exact isPrivate />
    <Route path="/login" component={Login} exact noLayout />
    <Route path="/indiker/:id" component={Indiker} isPrivate />
    <Route path="/financeiro" component={Financial} isPrivate />
    <Route path="/recuperar-senha" component={ForgotPassword} noLayout />
    <Route path="/redefinir-senha" component={ResetPassword} noLayout />
  </Switch>
);

export default Routes;
