import React, { createContext, useContext, useCallback, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

interface ToastProps {
  message: string;
  type: 'success' | 'warning' | 'error' | 'info';
}

interface ToastContextData {
  addToast(toast: ToastProps): void;
  removeToast(): void;
  toast: ToastProps;
  isOpen: boolean;
}

const initialValue = {} as ToastProps;

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const [toast, setToast] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);

  const addToast = useCallback((data: ToastProps) => {
    setToast(data);
    setIsOpen(true);

    setTimeout(() => {
      setIsOpen(false);
    }, 5000);
  }, []);

  const removeToast = useCallback(() => {
    setToast(initialValue);
    setIsOpen(false);
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast, isOpen, toast }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isOpen}
      >
        <Alert severity={toast.type} elevation={6} variant="filled">
          {toast.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextData => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within an ToastProvider');
  }

  return context;
};

export { ToastProvider, useToast };
