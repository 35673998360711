import React, { useCallback } from 'react';

import { FilterName } from './props';
import { Typography } from '@material-ui/core';

interface FilterElementProps {
  title: string;
  name: FilterName;
  active?: boolean;
  disabled?: boolean;
  onChange: (name: FilterName, value: unknown) => void;
}

const FilterElement: React.FC<FilterElementProps> = ({
  title,
  name,
  active,
  disabled,
  onChange,
}) => {
  const classList = ['menu-item'];
  if (active) classList.push('active');

  const handleClick = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const filterValue = ev.target.checked;
      onChange(name, filterValue);
    },
    [name, onChange]
  );

  return (
    <div className={classList.join(' ')}>
      <input type="checkbox" onChange={handleClick} disabled={disabled} />
      <Typography component="span">{title}</Typography>
    </div>
  );
};

export default FilterElement;
