import React, { useCallback } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { FaCopy } from 'react-icons/fa';

import { useToast } from '../../context/toast';
import { BankAccount } from '../../dtos/IndikerDTO';

const pixType: { [key: number]: string } = {
  1: 'Telefone',
  2: 'CPF',
  3: 'E-mail',
};

const DataItem: React.FC<{ value: unknown }> = ({ value, children }) => {
  const { addToast } = useToast();

  const handleCopy = useCallback(async () => {
    try {
      navigator.clipboard.writeText(String(value));

      addToast({
        message: 'Valor copiado',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      addToast({
        message: 'Erro copiando o valor',
        type: 'error',
      });
    }
  }, [addToast, value]);

  return (
    <Typography className="data">
      {children}
      {Boolean(value) && (
        <IconButton style={{ padding: 2 }} onClick={handleCopy} title="Copiar">
          <FaCopy size={14} style={{ marginLeft: 8 }} />
        </IconButton>
      )}
    </Typography>
  );
};

const PaymentData: React.FC<{
  data: BankAccount;
}> = ({ data }) => (
  <>
    <div className="info">
      <Typography className="title">Dados Bancários</Typography>
      {data.transfer_type === 1 && (
        <>
          <DataItem value={data.pix_type ? pixType[data.pix_type] : '-'}>
            Tipo de Chave: {data.pix_type ? pixType[data.pix_type] : '-'}
          </DataItem>
          <DataItem value={data.pix_key}>
            Chave pix: {data.pix_key || '-'}
          </DataItem>
        </>
      )}
      {data.transfer_type === 2 && (
        <>
          <DataItem value={data.bank_id}>
            Banco: {data.bank.title || '-'}
          </DataItem>
          <DataItem value={data.agency}>Agência: {data.agency || '-'}</DataItem>
          <DataItem value={data.account}>Conta: {data.account || '-'}</DataItem>
          <DataItem value={data.account_type_title}>
            Tipo de conta: {data.account_type_title || '-'}
          </DataItem>
        </>
      )}
    </div>
    <div className="info">
      <Typography className="title">Conta de terceiros</Typography>
      <DataItem value={data.account_owner_fisrtname}>
        Nome: {!data.account_owner_is_user ? data.account_owner_fisrtname : '-'}
      </DataItem>
      <DataItem value={data.account_owner_lastname}>
        Sobrenome:{' '}
        {!data.account_owner_is_user ? data.account_owner_lastname : '-'}
      </DataItem>
      <DataItem value={data.account_owner_document}>
        CPF: {!data.account_owner_is_user ? data.account_owner_document : '-'}
      </DataItem>
      <DataItem value={data.account_owner_phone}>
        Telefone: {!data.account_owner_is_user ? data.account_owner_phone : '-'}
      </DataItem>
    </div>
  </>
);

export default PaymentData;
