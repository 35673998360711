import axios, { AxiosError } from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({ baseURL });

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@IndikeGanheCRM:token');

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError<unknown>) => {
    const errorMsg = error.response?.data || null;
    if (errorMsg) return Promise.reject(errorMsg);
    return Promise.reject(error);
  }
);

export default api;
