export interface FiltersProps {
  status: number | null;
  search: string;
}

export type FilterName = keyof FiltersProps;

export const initialValues: FiltersProps = {
  status: null,
  search: '',
};
