import React from 'react';
import { TableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
interface TableDataProps {
  loading: boolean;
  classname?: string;
  align?: 'center' | 'left' | 'right' | 'justify';
}

const TableData: React.FC<TableDataProps> = ({
  loading,
  classname,
  align,
  children,
}) => {
  return (
    <TableCell className={classname} align={align}>
      {loading ? <Skeleton width="100%" height={29} /> : children}
    </TableCell>
  );
};

export default TableData;
