import React, { useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useData } from '../../context/data';
import { WithdrawStatusDTO } from '../../dtos/WithdrawDTO';

interface WithdrawStatusProps {
  withdrawId: number;
}

const useStyles = makeStyles({
  root: {
    '& .MuiChip-label': {
      color: '#ffffff',
      fontSize: '12px',
      fontWeight: 500,
    },
  },
});

const WithdrawStatus: React.FC<WithdrawStatusProps> = ({ withdrawId }) => {
  const [status, setStatus] = useState<WithdrawStatusDTO | null>(null);
  const { withdrawStatuses } = useData();
  const classes = useStyles();

  useEffect(() => {
    if (withdrawStatuses.length) {
      const selectedStatus = withdrawStatuses.find(
        (item) => item.id === withdrawId
      );
      setStatus(selectedStatus || null);
    }
  }, [withdrawId, withdrawStatuses]);

  return (
    status && (
      <Chip
        label={status.name}
        className={classes.root}
        style={{
          backgroundColor: status.color,
        }}
      />
    )
  );
};

export default WithdrawStatus;
