import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from '@material-ui/core';

import { useStyles } from './styles';

interface DeactivateModalProps {
  open: boolean;
  onClose(): void;
  onAccept(reason: string): void;
}

const DeactivateModal: React.FC<DeactivateModalProps> = ({
  open,
  onClose,
  onAccept,
}) => {
  const [reason, setReason] = useState<string | null>(null);
  const classes = useStyles();

  useEffect(() => {
    return () => setReason(null);
  }, []);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value.trim();
      setReason(value || null);
    },
    []
  );

  const handleAccept = useCallback(() => {
    if (!reason) return;
    onAccept(reason);
  }, [onAccept, reason]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      className={classes.root}
    >
      <DialogContent>
        <Typography className={classes.title}>
          Tem certeza que deseja desativar o acesso deste Indiker?
        </Typography>
        <Typography className={classes.confirmationLabel}>
          Insira o motivo da desativação
        </Typography>
        <TextField multiline onChange={handleOnChange} />
        <div className={classes.buttons}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={!Boolean(reason)}
            onClick={handleAccept}
          >
            Confirmar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeactivateModal;
