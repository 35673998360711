import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.default,
    width: '100%',
    height: '100vh',
  },
  container: {
    padding: '60px 30px',
    width: 340,
  },
  form: {
    marginTop: 60,
  },
  input: {
    marginBottom: 18,
  },
  linkContainer: {
    textAlign: 'right',
    marginBottom: 30,
  },
  link: {
    color: '#828282DE',
    fontSize: '12px',
  },
}));
